import { Label } from "@radix-ui/react-label";
import { redirect } from "@remix-run/node";
import { Form } from "@remix-run/react";
import { eq } from "drizzle-orm";
import { ActionFunctionArgs } from "react-router";
import { z } from "zod";

import SubmitButton from "~/components/SubmitButton";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "~/components/ui/card";
import { Input } from "~/components/ui/input";
import { db } from "~/db";
import { otps } from "~/db/auth";
import { invites } from "~/db/tables/invites";
import { users } from "~/db/tables/users";
import { send } from "~/emails/login";
import { handleDbError } from "~/lib/error";

export default function Page() {
  return (
    <Form className="h-dvh flex items-center justify-center" method="POST">
      <Card className="max-w-full w-96 mx-6">
        <CardHeader>
          <CardTitle className="text-2xl">Belépés</CardTitle>
          <CardDescription>Írd be az email címed a belépéshez, erre fogsz kapni egy egyszeri kódot.</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid gap-2">
            <Label className="space-y-2">
              <p>Email</p>
              <Input name="email" type="email" required />
            </Label>
          </div>
        </CardContent>
        <CardFooter>
          <SubmitButton className="w-full">Belépés</SubmitButton>
        </CardFooter>
      </Card>
    </Form>
  );
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const sendEmailSchema = z.object({
  email: z.string().email(),
});

export async function action({ request }: ActionFunctionArgs) {
  const object = Object.fromEntries(await request.formData());
  const result = sendEmailSchema.safeParse(object);
  if (!result.success) {
    return result.error.flatten();
  }
  const { email } = result.data;
  const timestamp = new Date().getTime();
  const redirectUrl = `/login/otp?email=${email}&t=${timestamp}`;

  // TODO throttle by ip

  const inviteList = await db.select().from(invites).where(eq(invites.email, email));
  const userList = await db.select().from(users).where(eq(users.email, email));
  if (inviteList.length === 0 && userList.length === 0) {
    await delay(2000);
    return redirect(redirectUrl);
  }

  const existing = await db.query.otps.findFirst({
    where: eq(otps.email, email),
  });
  if (existing && timestamp < existing.expiresAt.getTime()) {
    await delay(2000);
    return redirect(redirectUrl);
  }

  const code = String(getRandomInt(100000, 999999));
  const expiresAt = new Date();
  expiresAt.setMinutes(expiresAt.getMinutes() + 5);

  try {
    // TODO add retry
    await send(email, { code });

    await db.delete(otps).where(eq(otps.email, email));
    await db.insert(otps).values({ email, code, expiresAt });

    return redirect(redirectUrl);
  } catch (error) {
    return handleDbError(error, result.data);
  }
}
